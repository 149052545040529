<template lang="pug">
.content
  .vertical-time-line
    .step
      .circle-holder
        .number 1
      .d-flex.container
        .title {{ $t('integrationFlow.integrationModal.general.copyApiKey') }}
        .description
          a(href="https://account.sendinblue.com/advanced/api" target="_blank") {{ $t('integrationFlow.integrationModal.general.goToApiKey') }}
    .step
      .circle-holder
        .number 2
      .d-flex.container
        .title {{ $t('integrationFlow.integrationModal.general.pasteApiKey') }}
        .description
          .input-holder
            om-input#apiKey.mb-auto.w-40.mr-3(
              v-model.trim="settings.apiKey"
              type="text"
              :label="$t('integrationFlow.integrationModal.general.pasteApiKey')"
            )
              template(#error v-if="validations.apiKey.$error && !validations.apiKey.required")
                span {{ $t('integrationFlow.integrationModal.general.apiKeyError') }}
    .step
      .circle-holder
        .number 3
      .d-flex.container
        .title {{ $t('integrationFlow.integrationModal.general.addName') }}
        .description
          .input-holder
            om-input#name.mb-auto.w-100(
              v-model.trim="settings.name"
              type="text"
              :placeholder="$t('integrationFlow.integrationModal.general.namePlaceholder')"
              :helpText="$t('integrationFlow.integrationModal.general.inputHelp')"
            )
              template(#error v-if="validations.name.$error && !validations.name.required")
                span {{ $t('requiredField') }}
</template>
<script>
  export default {
    props: {
      settings: {
        type: Object,
        required: true,
      },

      validations: {
        type: Object,
        required: true,
      },

      isNew: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>
