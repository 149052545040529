<template lang="pug">
.content-step2
  om-select#listName(
    v-model="listId"
    :options="lists"
    :label="$t('integrationFlow.integrationModal.general.listHelper', { type: 'Sendinblue' })"
    :placeholder="$t('integrationFlow.integrationModal.selectAList')"
  )
  #listName.error-msg.mt-3(v-if="hasError")
    span.text-danger(v-html="$t(`integrations.sendinblue.notifications.apiConnectError`)")
</template>
<script>
  import { IntegrationService } from '@/services/integrations/integrationService';

  export default {
    props: {
      settings: {
        type: Object,
        required: true,
      },

      validations: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        listId: null,
        lists: [],
        hasError: false,
      };
    },

    watch: {
      listId(option) {
        this.updateListSetting(option.key);
      },
    },

    async mounted() {
      // IMPORTANT to load data then set model (behavior of om-select)
      const { lists, fields } = await this.loadData();

      this.updateFieldsSetting(fields);

      if (!lists.length) {
        this.updateListSetting(null);
        return;
      }

      this.lists = lists;

      const key = this.settings.convertedSettings.listId;

      // om-select selected option only needs "key" from object
      this.listId = key ? { key } : null;
    },

    methods: {
      async loadData() {
        const integrationService = new IntegrationService(this.$apollo);
        const result = {
          lists: [],
          fields: [],
        };

        try {
          const { success, lists, fields } = await integrationService.fetchIntegrationData(
            this.settings.type,
            this.settings.id,
          );

          if (success) {
            result.lists = lists.map((list) => ({
              key: list.id,
              value: list.name,
            }));
            result.fields = fields.map((field) => ({ key: field.name, value: field.name }));
            result.fields.push({ key: 'EMAIL', value: 'EMAIL' });
          } else {
            this.errorNotification(result.error);
          }
        } catch (e) {
          this.errorNotification(e.message);
        }

        return result;
      },
      errorNotification(message) {
        this.hasError = true;
        this.$notify({
          type: 'error',
          text: message,
        });
        setTimeout(() => {
          this.$bus.$emit('integration-show-first-step');
        }, 2000);
      },

      updateListSetting(value) {
        const settings = { ...this.settings };
        settings.convertedSettings.listId = value;
        this.$emit('update:settings', settings);
      },

      updateFieldsSetting(value) {
        const settings = { ...this.settings };
        settings.fields = value;
        this.$emit('update:settings', settings);
      },
    },
  };
</script>
